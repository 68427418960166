import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from 'react-helmet';

/* GraphQL */

const Page = () => {

  const data = useStaticQuery(graphql`
    {
    allWpPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          title
          date(formatString: "D-MM-YYYY")
          slug
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  id
                  resize(height: 500, width: 1000) {
                    width
                    height
                    src
                  }
                  fixed {
                    base64
                    tracedSVG
                    aspectRatio
                    srcWebp
                    srcSetWebp
                    originalName
                    src
                  }
                }
              }
            }
          }
          categories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
    allWpCategory(filter: {name: {nin: ["Virtualmin", "Uncategorized", "API", "Blog", "Events", "Geen categorie", "Grid", "News", "ProFTPd", "Rounded"]}}) {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
  }
`)

 // Array of all news articles
 const allNews = data.allWpPost.edges

 // State for the list
 const [list, setList] = useState([...allNews.slice(0, 10)])

 // State to trigger oad more
 const [loadMore, setLoadMore] = useState(false)

 // State of whether there is more to load
 const [hasMore, setHasMore] = useState(allNews.length > 10)

 // Load more button click
 const handleLoadMore = () => {
   setLoadMore(true)
 }

 // Handle loading more articles
 useEffect(() => {
   if (loadMore && hasMore) {
     const currentLength = list.length
     const isMore = currentLength < allNews.length
     const nextResults = isMore
       ? allNews.slice(currentLength, currentLength + 10)
       : []
     setList([...list, ...nextResults])
     setLoadMore(false)
   }
 }, [loadMore, hasMore]) //eslint-disable-line

 //Check if there is more
 useEffect(() => {
   const isMore = list.length < allNews.length
   setHasMore(isMore)
 }, [list]) //eslint-disable-line




  
  const [activeCategoryName, setActiveCategoryName] = useState('all');

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Blog - Bart Sallé, freelance webdeveloper";
  const description = "Reviews, mijn interesses en mijn dagelijkse beslommeringen. Je vindt ze allemaal terug in mijn blog!";
  const url = "https://www.bartsalle.nl/blog/";

  // Laad meer komt uit dit artikel: https://www.erichowey.dev/writing/load-more-button-and-infinite-scroll-in-gatsby/

  var loop;

  if (activeCategoryName == 'all') {
    loop = (
      <div className='blogcontainer'>
          {list.map(({ node }) => (

            <div className={`blogpost ${(activeCategoryName==='all' || node.categories.nodes.some(element => (element.slug === activeCategoryName))) ? "" : "hide"}`}>

              <Link to = {`/${node.slug}/`}>
                <img src={node.featuredImage.node.localFile.childImageSharp.resize.src} />
                <span className='blogpost_date'>{node.date}</span>
                <h3 className='blogpost_title'>{node.title}</h3>
              </Link>

              
            </div>
          ))}

          {hasMore ? (
              <button onClick={handleLoadMore} className='button'>Meer laden...</button>
            ) : (
            <p>Dit is alles!</p>
            )}
        </div>
    );
  } else {
    loop = (
      <div className='blogcontainer'>
          {data.allWpPost.edges.map(({ node }) => (

            <div className={`blogpost ${(activeCategoryName==='all' || node.categories.nodes.some(element => (element.slug === activeCategoryName))) ? "" : "hide"}`}>

              <Link to = {`/${node.slug}/`}>
                <img src={node.featuredImage.node.localFile.childImageSharp.resize.src} />
                <span className='blogpost_date'>{node.date}</span>
                <h3 className='blogpost_title'>{node.title}</h3>
              </Link>

              
            </div>
          ))}
        </div>
    );
  }
  
  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="blog" />
      </Helmet>
      <div className="container blog">
        <h1>Blog</h1>
        <div className='forcoders'>Mijn technische blogs plaats ik sinds enige tijd op <a href="https://www.4coders.nl/" target="_blank">4coders.nl</a>.</div>
        <ul className='blogcategories'>
        <li>
          <a onClick={() => setActiveCategoryName('all')}>Alle posts</a>
        </li>
        {data.allWpCategory.edges.map(({ node }) => (
        <li>
          <a className={`${node.slug}`} onClick={() => setActiveCategoryName(node.slug)}>{node.name}</a>
        </li>
        ))}
        </ul>

          {loop}

      </div>
    </Layout>
  )

}

export default Page
